import Actions from 'Actions';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { COMPONENT_ACTION_TYPES } from 'lib/Constants';
import { chosenMoveComponentDestination } from 'store/reducers/StoryReducer';
import assertHasProperty from 'common/assertions/assertHasProperty';
import { assertIsNumber, assertIsString } from 'common/assertions';
export interface ActionComponentState {
  actionType: string | null;
  sourceBlockId: string | null;
  sourceComponentIndex: number | null;
  isUserChoosingTargetComponent: boolean;
}
export interface ActionComponentPayload {
  actionType: COMPONENT_ACTION_TYPES;
  blockId: string | null;
  componentIndex: number | null;
}

const validateActionComponentPayload = (payload: ActionComponentPayload): void => {
  assertHasProperty(payload, 'actionType');
  assertIsString(payload.actionType);
  assertHasProperty(payload, 'blockId');
  assertIsString(payload.blockId);
  assertHasProperty(payload, 'componentIndex');
  assertIsNumber(payload.componentIndex);
};

const initialState = {
  actionType: null,
  sourceBlockId: null,
  sourceComponentIndex: null,
  isUserChoosingTargetComponent: false
} as ActionComponentState;

/* Action Creators with validation in prepare callback */
export const actionComponentStart = createAction(
  Actions.ACTION_COMPONENT_START,
  (payload: ActionComponentPayload) => {
    validateActionComponentPayload(payload);
    return { payload };
  }
);
export const actionComponentCancel = createAction(Actions.ACTION_COMPONENT_CANCEL);

const actionComponentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actionComponentStart, (state, action) => {
      const { blockId, componentIndex, actionType } = action.payload;
      state.sourceBlockId = blockId;
      state.sourceComponentIndex = componentIndex;
      state.isUserChoosingTargetComponent = true;
      state.actionType = actionType;
    })
    .addCase(chosenMoveComponentDestination, (state) => {
      state.isUserChoosingTargetComponent = false;
    })
    .addCase(actionComponentCancel, (state) => {
      state.isUserChoosingTargetComponent = false;
      state.sourceBlockId = null;
      state.sourceComponentIndex = null;
    });
});

export default actionComponentReducer;
