import _ from 'lodash';
import React, { FunctionComponent } from 'react';

import { actionComponentCancel } from 'store/reducers/ActionComponentReducer';
import { chosenMoveComponentDestination } from 'store/reducers/StoryReducer';
import { selectors } from 'store/selectors/ActionComponentSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { StorytellerState } from 'store/StorytellerReduxStore';

import { COMPONENT_ACTION_TYPES } from 'lib/Constants';
import classnames from 'classnames';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';

import I18n from 'common/i18n';

export interface ComponentActionOverlayProps {
  blockId?: string;
  componentIndex?: number;
}

const ComponentActionOverlay: FunctionComponent<ComponentActionOverlayProps> = ({
  blockId,
  componentIndex
}) => {
  const dispatch = useDispatch();

  // Use useSelector hooks for all state access
  const isUserChoosingMoveDestination = useSelector(selectors.isUserChoosingMoveDestination);

  const isComponentBeingMoved = useSelector((state: StorytellerState) =>
    blockId && componentIndex != null
      ? selectors.isComponentBeingMoved(blockId, componentIndex, state)
      : false
  );

  const isComponentValidMoveDestination = useSelector((state: StorytellerState) =>
    blockId && componentIndex != null
      ? selectors.isComponentValidMoveDestination(blockId, componentIndex, state)
      : false
  );

  // Get source info with hooks
  const sourceBlockId = useSelector(selectors.getSourceBlockId);
  const sourceComponentIndex = useSelector(selectors.getSourceComponentIndex);

  const isActive = isUserChoosingMoveDestination;
  const shouldShowActionButton = isComponentValidMoveDestination;
  const shouldShowCancelButton = isComponentBeingMoved;
  const actionButtonText = I18n.t('editor.components.edit_controls.swap_here');

  if (!isActive) {
    return null;
  }

  const overlayClasses = classnames('component-edit-action-overlay', {
    'action-target': shouldShowActionButton,
    'action-source': shouldShowCancelButton
  });

  const onActionClick = (): void => {
    const sourceComponent = {
      blockId: sourceBlockId,
      componentIndex: sourceComponentIndex
    };

    dispatch(
      chosenMoveComponentDestination({
        blockId,
        componentIndex,
        sourceComponent
      })
    );
  };

  const onCancelAction = (): void => {
    dispatch(actionComponentCancel());
  };

  const overlayProps = {
    className: overlayClasses,
    onClick: shouldShowActionButton ? onActionClick : _.noop
  };

  const renderInnerComponentOverlay = (): JSX.Element => {
    return (
      <div {...overlayProps}>
        {shouldShowActionButton && (
          <button className="btn btn-alternate-2 btn-action-target">{actionButtonText}</button>
        )}
        {shouldShowCancelButton && (
          <button className="btn btn-simple btn-action-cancel" onClick={onCancelAction || _.noop}>
            {I18n.t('editor.components.edit_controls.cancel')}
          </button>
        )}
      </div>
    );
  };

  return shouldUseReactComponentBase() ? (
    <div className={`component-edit-${COMPONENT_ACTION_TYPES.MOVE}-overlay-container`}>
      {renderInnerComponentOverlay()}
    </div>
  ) : (
    renderInnerComponentOverlay()
  );
};

export default ComponentActionOverlay;
