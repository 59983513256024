import $ from 'jquery';
import React, { useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { isEqual } from 'lodash';

import * as SocrataVisualizations from 'common/visualizations';
import MostRecentlyUsed from 'common/most_recently_used';
import 'common/notifications/main';
import 'common/site_wide';
import AssetActionBar from 'common/components/AssetActionBar';
import PersonalizedStoryCreator from 'view/PersonalizedStoryCreator';

import Environment from 'StorytellerEnvironment';
import migrateStoryData from 'lib/migrateStoryData';
import 'lib/components/block-component-renderers/shared/componentBase';
import 'lib/components/block-component-renderers';
import 'lib/components/Modal';
import StorytellerUtils from 'lib/StorytellerUtils';
import { isFlexibleStory } from 'lib/FlexibleLayoutUtils';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'edit... Remove this comment to see the full error message
import StoryCopierRenderer from 'editor/renderers/StoryCopierRenderer';
import { windowSizeBreakpointStore } from 'editor/stores/WindowSizeBreakpointStore';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './Pr... Remove this comment to see the full error message
import PresentationMode from './PresentationMode';
import ViewBlockSection from './components/ViewBlockSection';
import AssetActionItem from './components/AssetActionItem';
import { getView } from 'common/core/views_service';
import { getCurrentUser } from 'common/current_user';
import FeatureFlag from 'common/feature_flags';
import PdfCreatedAtItem from './components/PdfCreatedAtItem';
import EmbedPersonalizedResetButton from './components/EmbedPersonalizedResetButton';
import ClassicBlockSection from 'editor/renderers/ClassicBlockSection';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';
import { View } from 'common/types/view';
import InteractiveUser from 'common/types/users/interactiveUser';

/**
 * Redux store imports
 */
import { StorytellerReduxStore, StorytellerState } from 'store/StorytellerReduxStore';
import { createStory } from 'store/TopLevelActions';
import { populateGlobalFilter, populateDatasetMetadata } from 'store/reducers/DataSourceReducer';
import { selectors as storySelectors } from 'store/selectors/StorySelectors';
import { getIsPersonalizedVersion } from 'store/selectors/PersonalizedStorySelectors';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

interface IViewStory {
  onFinishRenderCallback?: () => void;
}

interface AABWrapperProps {
  user: InteractiveUser;
  view: View;
}

// We must attach our Redux Store in view mode so that certain actions can be dispatched from the AssetActionBar.
StorytellerUtils.export(StorytellerReduxStore, 'storyteller.reduxStore');

const renderAssetActionItem = async () => {
  const currentUser = getCurrentUser();
  const currentView = await getView(Environment.STORY_UID);

  if (
    $('.btn-watch-asset-button').length > 0 &&
    FeatureFlag.value('enable_screenshot_subscriptions') &&
    FeatureFlag.value('enable_embedded_schedule_send') &&
    Environment.RENDER_EMBEDDED_SCHEDULE_SEND
  ) {
    ReactDOM.render(
      <AssetActionItem user={currentUser} view={currentView} />,
      document.getElementsByClassName('btn-watch-asset-button')[0]
    );
  }
};

const renderPdfCreatedAtItem = async () => {
  if (Environment.RENDER_PDF_CREATED_TIMESTAMP) {
    const currentUser = await getCurrentUser();
    if ($('#pdf-created-at-timestamp').length > 0) {
      ReactDOM.render(
        <PdfCreatedAtItem user={currentUser} />,
        document.getElementById('pdf-created-at-timestamp')
      );
    }
  }
};

const AABWrapper: React.FC<AABWrapperProps> = (props) => {
  const { user, view } = props;
  const isPersonalizedVersion = useSelector(getIsPersonalizedVersion);

  return <AssetActionBar user={user} view={view} personalizedStory={isPersonalizedVersion} />;
};

const renderResetPersonalizationButton = () => {
  if (Environment.RENDER_EMBEDDED_PERSONALIZED_RESET) {
    if ($('.btn-reset-personalization').length > 0) {
      ReactDOM.render(
        <Provider store={StorytellerReduxStore}>
          <EmbedPersonalizedResetButton />
        </Provider>,
        document.getElementsByClassName('btn-reset-personalization')[0]
      );
    }
  }
};

const ViewStory: React.FC<IViewStory> = (props) => {
  const { onFinishRenderCallback } = props;
  useEffect(() => {
    if (onFinishRenderCallback) {
      onFinishRenderCallback();
    }
  }, [onFinishRenderCallback]);

  const blockIds = useSelector(
    (state: StorytellerState) => storySelectors.getStoryBlockIds(state, Environment.STORY_UID!),
    isEqual
  );

  const blocks = blockIds.map((blockId: string, index: number) => {
    const components = storySelectors.getBlockComponents(StorytellerReduxStore.getState(), blockId);
    if (isFlexibleStory()) {
      return <ViewBlockSection key={blockId} components={components} blockId={blockId} />;
    } else {
      return (
        <ClassicBlockSection
          key={blockId}
          blockId={blockId}
          components={components}
          storyUid={Environment.STORY_UID!}
          blockIndex={index}
          blockCount={blockIds.length}
          editMode={false}
        />
      );
    }
  });

  return <>{blocks}</>;
};

function _renderStory(props: IViewStory = {}) {
  const { onFinishRenderCallback } = props;

  // Is this using ComponentBaseReact or componentBaseJQuery?
  const componentRenderClass = shouldUseReactComponentBase() ? 'uses-cbr' : 'uses-cbj';
  $('.user-story').addClass(componentRenderClass);

  ReactDOM.render(
    <Provider store={StorytellerReduxStore}>
      <ViewStory onFinishRenderCallback={onFinishRenderCallback} />
    </Provider>,
    document.getElementsByClassName('user-story')[0]
  );
}

$(function () {
  /** Do not read directly from STORY_DATA! Instead, prefer reading from the Redux Store. */
  const STORY_DATA = Environment.STORY_DATA!;
  const PERSONALIZED_STORY_DATA = Environment.PERSONALIZED_STORY_DATA!;
  migrateStoryData(STORY_DATA).then(() => {
    renderAssetActionItem();
    renderPdfCreatedAtItem();

    SocrataVisualizations.views.RowInspector.setup();

    windowSizeBreakpointStore.addChangeListener(_applyWindowSizeClass);

    const $userStoryContainer = $('.user-story-container');
    const $userStory = $('.user-story');

    if (FeatureFlag.valueOrDefault('enable_enhanced_table_print', false)) {
      $userStory.addClass('enhanced-table-print');
    }

    function _resizeVizOnPrint() {
      $('.socrata-visualization-renderer').trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
    }

    // This doesn't work in FF, but in Chrome it tells the visualizations to resize themselves
    // when Ctrl+P is pressed or Print is chosen from the browser menu.
    if (
      typeof window.matchMedia === 'function' &&
      typeof window.matchMedia('print')?.addEventListener === 'function'
    ) {
      window.matchMedia('print').addEventListener('change', (e) => {
        if (e.matches) setTimeout(_resizeVizOnPrint);
      });
    }

    function _applyWindowSizeClass() {
      const windowSizeClass = windowSizeBreakpointStore.getWindowSizeClass();
      const unusedWindowSizeClasses = windowSizeBreakpointStore.getUnusedWindowSizeClasses();

      $userStoryContainer.removeClass(unusedWindowSizeClasses.join(' ')).addClass(windowSizeClass);

      $userStory.removeClass(unusedWindowSizeClasses.join(' ')).addClass(windowSizeClass);
    }

    /**
     * Populating the Redux store with the story data in view mode
     *
     * TODO: We should refactor this once we fully transition to Redux.
     * These should be set as the initial state and should not need
     * special actions to populate the store.
     */

    if (
      FeatureFlag.value('enable_saved_state_in_stories') &&
      PERSONALIZED_STORY_DATA?.uid &&
      PERSONALIZED_STORY_DATA.updatedAt &&
      STORY_DATA.updatedAt &&
      new Date(PERSONALIZED_STORY_DATA.updatedAt) > new Date(STORY_DATA.updatedAt)
    ) {
      StorytellerReduxStore.dispatch(createStory(PERSONALIZED_STORY_DATA));
      PersonalizedStoryCreator.loadPersonalizedStory();
      StorytellerReduxStore.dispatch(populateGlobalFilter(PERSONALIZED_STORY_DATA));
    } else {
      StorytellerReduxStore.dispatch(createStory(STORY_DATA));
      StorytellerReduxStore.dispatch(populateGlobalFilter(STORY_DATA));
    }
    StorytellerReduxStore.dispatch(populateDatasetMetadata());

    const onFinishRenderCallback = () => {
      // If the url has a hash, the element won't exist until the story is rendered. We can scroll to it now.
      if (window.location.hash) {
        const target = $(window.location.hash)[0];
        if (target) {
          target.scrollIntoView();
        }
      }
    };

    _renderStory({ onFinishRenderCallback });

    // PresentationMode must be rendered after the story, but because it has it's own ReactDOM.render,
    // it can't (and probably doesn't need to) go in the normal onFinishedRender callback.
    new PresentationMode();

    // This can only happen after presentation mode buttons are rendered
    renderResetPersonalizationButton();

    // Init window size
    _applyWindowSizeClass();

    const aabContainer = document.getElementById('storyteller-asset-action-bar-container');
    if (
      Environment.RENDER_ASSET_ACTION_BAR &&
      Environment.CURRENT_USER &&
      Environment.CORE_VIEW &&
      aabContainer
    ) {
      ReactDOM.render(
        <Provider store={StorytellerReduxStore}>
          <AABWrapper user={Environment.CURRENT_USER} view={Environment.CORE_VIEW} />
        </Provider>,
        aabContainer
      );

      /* eslint-disable no-new */
      new StoryCopierRenderer({
        storyCopierContainerElement: $('#make-a-copy-container')
      });
      /* eslint-enable no-new */
    }

    if (Environment.CURRENT_USER && Environment.STORY_UID) {
      new MostRecentlyUsed({ namespace: `socrata:assets:mru:${Environment.CURRENT_USER.id}` }).add(
        Environment.STORY_UID
      );
    }
  });
});
