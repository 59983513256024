import { debounce } from 'lodash';
import { ListenerMiddleware } from '@reduxjs/toolkit';
import Environment from 'StorytellerEnvironment';
import { AUTOSAVE_DEBOUNCE_TIME_IN_MILLISECONDS } from 'lib/Constants';
import PersonalizedStoryCreator from 'view/PersonalizedStoryCreator';
import { StorytellerState } from 'store/StorytellerReduxStore';
import { updateBlockComponent } from 'store/reducers/StoryReducer';
import { triggerPersonalizedStoryReset } from 'store/reducers/PersonalizedStoryReducer';
import FeatureFlags from 'common/feature_flags';
import {
  updateFilterParameterConfiguration,
  updateAllFilterParameterConfigurations
} from 'store/TopLevelActions';

// Actions that should trigger personalized story save
const PERSONALIZED_SAVE_ACTIONS = [
  updateBlockComponent?.type,
  updateFilterParameterConfiguration?.type,
  updateAllFilterParameterConfigurations?.type
];

const storyUid = Environment.STORY_UID;

const personalizedStoryMiddleware: ListenerMiddleware = (store) => (next) => (action) => {
  next(action);

  if (
    !FeatureFlags.value('enable_saved_state_in_stories') ||
    !Environment.CURRENT_USER ||
    !Environment.IS_STORY_PUBLISHED ||
    Environment.EDIT_MODE ||
    !action.type ||
    !storyUid
  )
    return;

  if (action.type === triggerPersonalizedStoryReset.type) {
    PersonalizedStoryCreator.resetPersonalizedStory();
  }

  if (PERSONALIZED_SAVE_ACTIONS.includes(action.type)) {
    savePersonalizedOnceSettled(store.getState());
  }
};

let personalizedSaveLock = false;
const personalizedSaveDebounceMsec = AUTOSAVE_DEBOUNCE_TIME_IN_MILLISECONDS;
const savePersonalizedOnceSettled = debounce(
  (state) => savePersonalizedASAP(state as StorytellerState),
  personalizedSaveDebounceMsec
);

const savePersonalizedASAP = (state: StorytellerState) => {
  if (!storyUid) {
    throw new Error('No story UID found');
  }

  if (personalizedSaveLock) {
    savePersonalizedOnceSettled(state);
    return;
  }

  personalizedSaveLock = true;

  PersonalizedStoryCreator.savePersonalizedStory()
    .catch((error: any) => {
      console.error('Personalized story save failed:', error);
    })
    .finally(() => {
      personalizedSaveLock = false;
    });
};

export default personalizedStoryMiddleware;
