import { StorytellerState } from 'store/StorytellerReduxStore';
import { blockRemovalConfirmationReducerSelector } from 'store/TopLevelSelector';

export const getBlocksThatNeedConfirmation = (state: StorytellerState) =>
  blockRemovalConfirmationReducerSelector(state).confirmationNeededBlocks;

export const needsConfirmation = (state: StorytellerState, blockId: string): boolean => {
  const confirmationBlocks = getBlocksThatNeedConfirmation(state);
  return (confirmationBlocks ?? {})[blockId] === true;
};
