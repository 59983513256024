import { createReducer, createAction } from '@reduxjs/toolkit';

export const personalizedStorySaved = createAction('PERSONALIZED_STORY_SAVED');
export const personalizedStoryLoaded = createAction('PERSONALIZED_STORY_LOADED');
export const triggerPersonalizedStoryReset = createAction('TRIGGER_PERSONALIZED_STORY_RESET');
export const personalizedStoryReset = createAction('PERSONALIZED_STORY_RESET');

interface PersonalizedStoryState {
  updatedAt: string | null;
  isPersonalizedVersion: boolean;
}

const initialState: PersonalizedStoryState = {
  updatedAt: null,
  isPersonalizedVersion: false
};

const personalizedStoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(personalizedStorySaved, (state) => {
      state.isPersonalizedVersion = true;
    })
    .addCase(personalizedStoryLoaded, (state) => {
      state.isPersonalizedVersion = true;
    })
    .addCase(personalizedStoryReset, (state) => {
      state.isPersonalizedVersion = false;
    });
});

export default personalizedStoryReducer;
