import { each } from 'lodash';
import Actions from 'Actions';
import { createAction, createReducer } from '@reduxjs/toolkit';

/* Responsible for:
 * Tracking which blocks need a confirmation dialog to remove them. Any block
 * that has been saved or edited needs confirmation. Aka: ask unless it is a
 * newly added block that has not been edited.
 *
 * Registers to:
 * - BLOCK_UPDATE_COMPONENT
 *
 * Provides:
 * - needsConfirmation(blockId)   // used in the remove block listener
 */

export interface BlockNeedsConfirmationState {
  confirmationNeededBlocks: {
    [blockId: string]: boolean;
  };
}

const initialState = {
  confirmationNeededBlocks: {}
} as BlockNeedsConfirmationState;

/* Action Creators */
export const createBlockConfirmation = createAction<string[] | undefined>(Actions.BLOCK_CONFIRMATION_CREATE);
export const updateBlockConfirmation = createAction<string | undefined>(Actions.BLOCK_CONFIRMATION_UPDATE);

const blockRemovalConfirmationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createBlockConfirmation, (state, action) => {
      const { payload } = action;
      const blockIds = payload;
      const confirmationBlocks: Record<string, boolean> = {};

      if (blockIds) {
        each(blockIds, (blockId) => {
          confirmationBlocks[blockId] = true;
        });
        state.confirmationNeededBlocks = confirmationBlocks;
      }
    })
    .addCase(updateBlockConfirmation, (state, action) => {
      const { payload } = action;
      const blockId = payload;
      state.confirmationNeededBlocks[blockId] = true;
    });
});

export default blockRemovalConfirmationReducer;
