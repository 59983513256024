import { isEmpty, isEqual, keys, get, isPlainObject, omit } from 'lodash';
import Environment from 'StorytellerEnvironment';
import Constants from 'lib/Constants';
import { assertHasProperty, assertIsOneOfTypes, assertHasProperties } from 'common/assertions';
import { StorytellerState } from 'store/StorytellerReduxStore';
import { LAYOUT_OPTIONS } from 'lib/Constants';
import { Stories, StoryData, Story, StorySerialized, BlockDict } from 'types';
import { ReportDataSource } from 'common/types/reportFilters';
import { serializeBlockFromState } from './Blocks';
import { FeatureFlags } from 'common/feature_flags';
import {
  isSaveImpossibleDueToConflict,
  isStorySaveInProgress as isStorySaveInProgressHelper
} from 'store/selectors/StorySaveStatusSelectors';
import {
  storyReducerStoriesSelector,
  storyReducerBlocksSelector,
  storySaveStatusSelector as storySaveStatusSelectorHelper
} from 'store/TopLevelSelector';
import { selectors } from 'store/selectors/DataSourceSelectors';

interface Permission {
  isPublic?: boolean;
  isInternal?: boolean;
}

/**
 * Checks to see if a story exists
 * @param storyUid
 * @param stories
 */
export const doesStoryExist = (storyUid: string, stories: Stories) => {
  return stories.hasOwnProperty(storyUid);
};

//=============================================================================
// Story getters
//
// Below are functions that are used to retrieve information about one or more
// stories
//=============================================================================

export const getStory = (storyUid: string, stories: Stories) => {
  assertIsOneOfTypes(storyUid, 'string');
  assertHasProperty(
    stories,
    storyUid,
    `Story with uid ${storyUid} does not exist. Existing stories: ${keys(stories)}`
  );

  const story = stories[storyUid] as Story;
  return story;
};

export const getStoryPublishedStory = (storyUid: string, stories: Stories) => {
  const story = getStory(storyUid, stories);

  return story.publishedStory;
};

export const getStoryTitle = (storyUid: string, stories: Stories) => {
  const story = getStory(storyUid, stories);

  return story.title;
};

export const getStoryDescription = (storyUid: string, stories: Stories) => {
  const story = getStory(storyUid, stories);

  return story.description;
};

export const getStoryMetadata = (storyUid: string, stories: Stories) => {
  const story = getStory(storyUid, stories);

  return story.metadata;
};

export const getStoryTileTitle = (storyUid: string, stories: Stories) => {
  const story = getStory(storyUid, stories);

  return isEmpty(story?.tileConfig?.title) ? story.title : story.tileConfig.title;
};

export const getStoryTileDescription = (storyUid: string, stories: Stories) => {
  const story = getStory(storyUid, stories);

  return isEmpty(story?.tileConfig?.description) ? story.description : story.tileConfig.description;
};

export const getStoryTheme = (storyUid: string, stories: Stories) => {
  const story = getStory(storyUid, stories);

  return story.theme || 'forge';
};

// Keep in sync with get_current_layout in layout_helper.rb
export const getStoryLayout = (storyUid: string, stories: Stories) => {
  const { layout, theme } = getStory(storyUid, stories);
  return (
    layout || // layout is always null unless it's been set by the user
    get(Environment, ['CUSTOM_THEME_LAYOUT_MAP', theme as string]) ||
    (FeatureFlags.value('flexible_layout_stories_only_tir_domains')
      ? LAYOUT_OPTIONS.LARGE
      : LAYOUT_OPTIONS.NARRATIVE)
  );
};

export const getStoryUpdatedAt = (storyUid: string, stories: Stories) => {
  const story = getStory(storyUid, stories);
  return story.updatedAt;
};

export const getStoryBlockIds = (storyUid: string, stories: Stories) => {
  const story = getStory(storyUid, stories);
  return story.blockIds;
};

/**
 * Returns the title of the story from the Environment
 * This will check to see if the story uid exists before
 * returning the correct title
 * @returns String
 */
export const getStoryTitleToCopy = (stories: Stories) => {
  return doesStoryExist(Environment.STORY_UID ?? '', stories)
    ? getStoryTitle(Environment.STORY_UID as string, stories) // Latest title, user may have edited it.
    : Environment?.STORY_DATA?.title;
};

export const getBlockAndComponent = (blockId: string, componentIndex: string | number, blocks: BlockDict) => {
  assertIsOneOfTypes(componentIndex, 'number', 'string');
  assertIsOneOfTypes(blockId, 'string');

  const index = typeof componentIndex === 'string' ? parseInt(componentIndex, 10) : componentIndex;
  // Verify that it is a number *after* the parseInt but report on its
  // original type.
  if (isNaN(index) || index < 0) {
    throw new Error(`Invalid component index: "${componentIndex}".`);
  }
  const block = blocks[blockId];
  const blockLength = block.components.length;
  if (index > blockLength) {
    throw new Error(
      `\`index\` argument is out of bounds; index: "${index}", block.components.length: "${blockLength}".`
    );
  }

  const component = block.components[index];

  return { block, component };
};

//=============================================================================
// State helpers
//
// Below are functions that are used to retrieve state changes
//=============================================================================

export const draftHasChanges = (storyUid: string, stories: Stories, digest: string) => {
  const publishedStory = getStoryPublishedStory(storyUid, stories);

  if (!publishedStory) {
    return true;
  }

  return publishedStory.digest !== digest;
};

export const isStorySavePossible = (state: StorytellerState) => {
  return !(isStorySaveInProgressHelper(state) || isStorySaved(state) || isSaveImpossibleDueToConflict(state));
};

export const isStorySaved = (state: StorytellerState) => {
  const storyUid = Environment.STORY_UID as string;
  const stories = storyReducerStoriesSelector(state);

  const story = getStory(storyUid, stories);
  const dataSources = selectors.getDataSourceForSerialize(state);
  const blocks = storyReducerBlocksSelector(state);

  const currentSerializedStory = serializeStory(story, blocks, dataSources);
  const lastSerializedStory = storySaveStatusSelectorHelper(state).lastSerializedStory;
  const lastSaveError = storySaveStatusSelectorHelper(state).lastSaveError;
  const isStorySaving = !!isStorySaveInProgressHelper(state);
  return lastSerializedStory
    ? !isStoryDirty(currentSerializedStory, lastSerializedStory, isStorySaving, lastSaveError)
    : false;
};

//=============================================================================
// Permission helpers
//
// Below are functions that are used to retrieve permission information about
// one or more stories
//=============================================================================

/**
 * Checks to see if a story is public
 * @returns boolean
 */
export const isStoryPublic = (storyUid: string, stories: Stories) => {
  return !!getStoryPermissions(storyUid, stories).isPublic;
};

/**
 * Checks to see if a story is private (internal)
 * @returns boolean
 */
export const isStoryInternal = (storyUid: string, stories: Stories) => {
  return !!getStoryPermissions(storyUid, stories).isInternal;
};

/**
 * Gets permissions for a story
 * @param storyUid
 * @param stories
 * @returns
 */
export const getStoryPermissions = (storyUid: string, stories: Stories): Permission => {
  const story = getStory(storyUid, stories);

  let permissions = story.permissions;

  // Ensure that property checks on the return value won't fail
  // due to null reference.
  if (!isPlainObject(permissions)) {
    permissions = {};
  }

  return permissions;
};

/**
 * Checks to see if the Story needs to be updated by comparing the current StoryJson to the previous StroyJson
 * This also accounts for last save error and if the story is currently saving
 * @param currentSerializedStory
 * @param previousSerializedStory
 * @param isStorySaveInProgress
 * @param lastSaveError
 * @returns boolean
 */
export const isStoryDirty = (
  currentSerializedStory: StorySerialized,
  previousSerializedStory: StorySerialized,
  isStorySaveInProgress: boolean,
  lastSaveError: string | null
) => {
  // Metadata properties are saved through a different mechanism, so the save button
  // should not light up for changes _only_ to the metadata.
  const metadataProperties = ['title', 'description', 'metadata'];
  return (
    isStorySaveInProgress ||
    !!lastSaveError ||
    !isEqual(
      omit(currentSerializedStory, metadataProperties),
      omit(previousSerializedStory, metadataProperties)
    )
  );
};

export const isDraftUnpublished = (storyUid: string, stories: Stories, digest: string) => {
  const publishedStory = getStoryPublishedStory(storyUid, stories);
  if (!publishedStory) {
    return true;
  }

  return publishedStory.digest !== digest;
};

//=============================================================================
// Transformer helpers
//
// Below are functions that are used to alter the StoryStore state object
//=============================================================================

// Serialize the story for saving purposes
export const serializeStory = (story: Story, blocks: BlockDict, dataSource?: ReportDataSource) => {
  const serializedStory: StorySerialized = {
    uid: story.uid,
    title: story.title,
    description: story.description,
    dataSource,
    theme: story.theme,
    layout: story.layout,
    blocks: story.blockIds.map((blockId) => serializeBlockFromState(blockId, blocks))
  };
  return serializedStory;
};

export const validateStoryData = (storyData: StoryData) => {
  assertIsOneOfTypes(storyData, 'object');
  assertHasProperties(storyData, 'uid', 'title', 'description', 'tileConfig', 'blocks', 'permissions');

  if (storyData.uid.match(Constants.FOUR_BY_FOUR_PATTERN) === null) {
    throw new Error(`\`uid\` property is not a valid four-by-four: "${JSON.stringify(storyData.uid)}".`);
  }
};
