import Actions from 'Actions';
import { createAction, createReducer } from '@reduxjs/toolkit';

export interface ShareAndEmbedModalState {
  isShareAndEmbedModalOpen: boolean;
}

/* Action Creators */
export const openShareAndEmbedModal = createAction(Actions.SHARE_AND_EMBED_MODAL_OPEN);
export const closeShareAndEmbedModal = createAction(Actions.SHARE_AND_EMBED_MODAL_CLOSE);

const initialState: ShareAndEmbedModalState = { isShareAndEmbedModalOpen: false };

const shareAndEmbedReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openShareAndEmbedModal, (state) => {
      state.isShareAndEmbedModalOpen = true;
    })
    .addCase(closeShareAndEmbedModal, (state) => {
      state.isShareAndEmbedModalOpen = false;
    });
});

export default shareAndEmbedReducer;
