// Copies select properties off the window object as an encapsulated environment.

import InteractiveUser from 'common/types/users/interactiveUser';
import { View } from 'common/types/view';
import { GuidanceSummaryV2 } from 'common/types/approvals';

import { StoryData, StoryViewRights } from 'types';

export interface StorytellerFontTheme {
  description: string;
  id: string | number;
  css_variables: {
    '$body-font-stack': string;
    '$heading-font-stack': string;
    '$default-type-color': string;
    '$heading-type-color': string;
    [key: string]: string;
  };
  title: string;
}

export interface StorytellerCustomFontTheme extends StorytellerFontTheme {
  updated_at: number;
  domain_cname: string;
  persisted: boolean;
  class_name: string;
}

// We declare props in a class, because it lets us BOTH:
//  - Get a TS interface, so the environment object can be typed
//  - Get a string array of the keys we want, so we don't have to copy the entire window object
// Before this, we had a duplicate string array, which was annoying and error-prone
// https://stackoverflow.com/a/59806829/8412474

class StorytellerEnvironmentClass {
  AIRBRAKE?: object = undefined;
  APPROVALS_GUIDANCE?: GuidanceSummaryV2 = undefined;
  CAN_MANAGE_STORY_VISIBILITY?: boolean = undefined;
  CORE_SERVICE_APP_TOKEN?: string = undefined;
  CORE_VIEW?: View = undefined;
  CURRENT_USER?: InteractiveUser = undefined;
  CURRENT_USER_STORY_AUTHORIZATION?: {
    viewRights: StoryViewRights;
  } = undefined;
  CUSTOM_THEMES?: StorytellerCustomFontTheme[] = undefined;
  CUSTOM_THEME_LAYOUT_MAP?: object = undefined;
  DEFAULT_THEMES?: { themes: StorytellerFontTheme[] } = undefined;
  DOWNTIMES?: object = undefined;
  EDIT_MODE?: boolean = undefined;
  EMBED_CODE_SANDBOX_IFRAME_ALLOWANCES?: object = undefined;
  ENVIRONMENT?: 'development' | 'test' | 'production' = undefined;
  IMAGES?: {
    COVER_IMAGE_ICON: string;
  } = undefined;
  IS_STORY_PUBLISHED?: boolean = undefined;
  PERSONALIZED_STORY_DATA?: StoryData = undefined;
  PRIMARY_OWNER_UID?: string = undefined;
  PUBLISHED_STORY_DATA?: StoryData = undefined;
  RELATIVE_URL_ROOT?: string = undefined;
  RENDER_ASSET_ACTION_BAR?: boolean = undefined;
  STORY_DATA?: StoryData = undefined;
  STORY_UID?: string = undefined;
  STORY_PREVIEW_URL?: string = undefined;
  STORY_VIEW_URL?: string = undefined;
  STORY_EDIT_URL?: string = undefined;
  THEMES_ASSET_PATH?: string = undefined;
  TRANSLATIONS?: object = undefined;
  RENDER_EMBEDDED_SCHEDULE_SEND?: boolean = undefined;
  RENDER_PDF_CREATED_TIMESTAMP?: boolean = undefined;
  RENDER_EMBEDDED_PERSONALIZED_RESET?: boolean = undefined;
}

export interface Environment extends StorytellerEnvironmentClass {}

const envProperties = Object.keys(new StorytellerEnvironmentClass());

const environment: Environment = Object.fromEntries(
  envProperties.map((prop) => [prop, (window as any)[prop]])
);

export default environment;
