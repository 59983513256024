import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import { isEqual, set, cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { Resizable } from 'react-resizable';
import classNames from 'classnames';

import Environment from 'StorytellerEnvironment';
import { selectors as dataSourceSelectors } from 'store/selectors/DataSourceSelectors';
import { selectors as storySelectors } from 'store/selectors/StorySelectors';
import { selectors as actionComponentSelectors } from 'store/selectors/ActionComponentSelectors';
import { StorytellerState } from 'store/StorytellerReduxStore';
import { updateBlockComponent } from 'store/reducers/StoryReducer';

import { useDispatch } from 'react-redux';
import { JQueryPlugin, BlockComponent } from 'types';

import ComponentEditControls from 'editor/components/ComponentEditControls';
import { shouldUseReactComponentBase, isFlexibleStory } from 'lib/FlexibleLayoutUtils';
import { runJQueryPlugin, socrataVizInvalidateSizeHelper } from 'editor/renderers/SharedRenderingUtils';

import {
  getComponentRenderer,
  getResizableComponentProps,
  isFixedHeightMediaComponent,
  isMediaComponent
} from 'lib/components/block-component-renderers/shared/BlockComponentRendererReactLiaison';

export interface StoryComponentRendererProps {
  blockId: string;
  componentIndex: number;
  containerClasses?: string[];
  // This is only used for view mode
  editMode: boolean;
}

const StoryComponentRenderer = forwardRef<HTMLDivElement, StoryComponentRendererProps>(
  ({ blockId, componentIndex, containerClasses = [], editMode }, ref) => {
    const componentRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => componentRef.current!, []);

    const theme = useSelector((state: StorytellerState) =>
      storySelectors.getStoryTheme(state, Environment.STORY_UID!)
    );
    const additionalFilters = useSelector(dataSourceSelectors.getGlobalFilters, isEqual);
    const parameterOverrides = useSelector(dataSourceSelectors.getParameterOverrides, isEqual);
    const componentData = useSelector((state: StorytellerState) => {
      return storySelectors.getBlockComponentAtIndex(state, blockId, componentIndex);
    }, isEqual);

    useSelector(actionComponentSelectors.isActionComponentStoreActive);

    const jQueryRendererToRun = getComponentRenderer(componentData.type);
    const currentRenderer = useRef<JQueryPlugin | null>(null);

    const jQueryResizableProps = getResizableComponentProps(jQueryRendererToRun, componentData);
    // When a componentHero gets an image, it suddenly becomes resizable and that messes with the DOM.
    // CBR-TODO: This is starting to feel too coupled. Not sure how to solve it yet,
    // but just acknowledging the code smell.
    const currentResizeSupported = useRef(jQueryResizableProps.resizeSupported);

    // Add classes to the container, replaces awful SCSS variables.
    if (isFixedHeightMediaComponent(componentData.type)) {
      containerClasses.push('fixed-height-media-component');
    } else if (isMediaComponent(componentData.type)) {
      containerClasses.push('media-component');
    }

    const componentEditControlsProps = {
      blockId,
      componentIndex,
      componentData,
      editMode
    };

    const pluginProps = useMemo(
      () => ({
        componentData,
        theme,
        editMode,
        blockId,
        componentIndex,
        additionalFilters,
        parameterOverrides
      }),
      [componentData, theme, editMode, blockId, componentIndex, additionalFilters, parameterOverrides]
    );

    // render the jQuery plugin on the ref
    useEffect(() => {
      if (componentRef?.current) {
        // Skip asset selectors in view mode
        if (!editMode && jQueryRendererToRun === JQueryPlugin.ASSET_SELECTOR) return;

        const needToChangeRenderer =
          currentRenderer.current !== jQueryRendererToRun ||
          (shouldUseReactComponentBase() &&
            currentResizeSupported.current !== jQueryResizableProps.resizeSupported); // For hero components
        if (needToChangeRenderer) {
          currentRenderer.current = jQueryRendererToRun;
          currentResizeSupported.current = jQueryResizableProps.resizeSupported;
        }
        runJQueryPlugin(componentRef.current, needToChangeRenderer, jQueryRendererToRun, pluginProps);
      }
      // No skipping renders. This will run any time componentData changes, including every keypress
      // According to my current performance testing, this is no longer slow.
    }, [jQueryRendererToRun, pluginProps, editMode, jQueryResizableProps]);

    const renderComponentContainer = () => {
      return (
        <div
          className={`component-container ${shouldUseReactComponentBase() ? '' : containerClasses.join(' ')}`}
          ref={componentRef}
          data-component-index={componentIndex}
          data-component-renderer-name={jQueryRendererToRun}
        />
      );
    };

    // Regardless of classic/flex, if the flag is off use CBJ only
    if (!shouldUseReactComponentBase()) {
      return renderComponentContainer();
    }

    const renderResizableComponentContainer = () => {
      return (
        <ComponentResizable
          ref={componentRef}
          componentData={componentData}
          blockId={blockId}
          componentIndex={componentIndex}
          editMode={editMode}
        />
      );
    };

    return (
      <div
        className={`component-base-container ${containerClasses.join(' ')}`}
        data-component-renderer-name={jQueryRendererToRun}
      >
        <ComponentEditControls {...componentEditControlsProps} />
        {!isFlexibleStory() ? renderResizableComponentContainer() : renderComponentContainer()}
      </div>
    );
  }
);

export default StoryComponentRenderer;

interface ComponentResizableProps {
  componentData: BlockComponent;
  blockId: string;
  componentIndex: number;
  editMode: boolean;
}

const ComponentResizable = forwardRef<HTMLDivElement, ComponentResizableProps>(
  ({ componentData, blockId, componentIndex, editMode }, ref) => {
    const componentRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const jQueryRendererToRun = getComponentRenderer(componentData.type);
    const jQueryResizableProps = useMemo(
      () => getResizableComponentProps(jQueryRendererToRun, componentData),
      // returns a new object every time, so we need to memoize it for the invalidate size useEffect
      [jQueryRendererToRun, componentData]
    );

    // Explicitly no dep array
    useImperativeHandle(ref, () => componentRef.current!);

    const fallbackHeight = jQueryResizableProps.defaultHeight || null;

    const [height, setHeight] = useState<number | null>(
      componentData.value?.layout?.height || fallbackHeight
    );
    // If the component starts with any kind of height, we'll never need the jQuery switchover behavior.
    const [shouldUseJqueryHeight, setShouldUseJqueryHeight] = useState(!height);

    // Send SOCRATA_VISUALIZATION_INVALIDATE_SIZE event when height changes, with jQuery helper
    useEffect(() => {
      if (shouldUseReactComponentBase() && jQueryResizableProps.resizeSupported && componentRef?.current) {
        socrataVizInvalidateSizeHelper(componentRef.current);
      }
    }, [jQueryResizableProps, height]);

    const onResize = (event: React.SyntheticEvent, { size }: { size: { width: number; height: number } }) => {
      let resizeHeight = size.height;

      // Pre-CBR, there was a dumb edge case for heights that we're matching in React.
      // If a user inserted a new viz, but never resized it, it's height was determined by CSS, not JS.
      // As soon as they start resizing, jQuery would help it switch to JS height.
      // `react-resizable` really wants to be in control of the height, so we have to lie to it.
      // We tell it the height is "1" initially and keep using the jQuery height and calling setHeight
      // until the re-render catches up and the jQuery given height and the React state height are within 5%.
      // CBR-TODO: consider forcing default heights/refactoring the CSS.
      if (shouldUseJqueryHeight) {
        const jQueryHeight =
          $(".block-edit[data-block-id='" + blockId + "']")
            .find(".component-container[data-component-index='" + componentIndex + "']")
            .height() || size.height;

        if (Math.abs(jQueryHeight - size.height) < jQueryHeight * 0.05) {
          setShouldUseJqueryHeight(false);
        } else {
          resizeHeight = jQueryHeight;
        }
      }

      setHeight(resizeHeight);

      // Update redux store
      const updatedComponentData = cloneDeep(componentData);
      set(updatedComponentData, 'value.layout.height', resizeHeight);

      dispatch(
        updateBlockComponent({
          blockId: blockId,
          componentIndex: componentIndex,
          type: updatedComponentData.type,
          value: updatedComponentData.value
        })
      );
    };

    // Ignore component height if resize is not supported, occurs when switching from measure chart to card.
    const finalHeight = jQueryResizableProps.resizeSupported ? height : fallbackHeight;

    const heightStyle = height ? { height: `${finalHeight}px` } : undefined;
    // I think assetSelectors are the only components which never need any kind of style.
    const classes = classNames(
      { 'react-resizable-style': !!height },
      { 'react-resizable-padding': jQueryResizableProps.resizeSupported }
    );

    const renderInnerDiv = () => {
      return (
        <div className={classes}>
          <div
            className={'component-container'}
            ref={componentRef}
            data-component-index={componentIndex}
            data-component-renderer-name={jQueryRendererToRun}
            style={heightStyle}
          />
        </div>
      );
    };

    if (!editMode || !jQueryResizableProps.resizeSupported) {
      return renderInnerDiv();
    }

    return (
      <Resizable
        height={height || 1}
        width={100} // Width doesn't matter since we're not resizing horizontally
        minConstraints={[100, jQueryResizableProps.resizeOptions?.minHeight || 0]}
        axis="y" // Only allow vertical resizing
        onResize={onResize}
        resizeHandles={['s']}
        handle={
          <div className="component-resize-handle">
            <div></div>
          </div>
        }
      >
        {renderInnerDiv()}
      </Resizable>
    );
  }
);
